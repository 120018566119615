import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { forwardRef } from 'react';

export const DateFormField = forwardRef<HTMLDivElement, DatePickerProps<Dayjs>>(
  ({ ...rest }, ref) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker ref={ref} sx={{ width: '100%' }} {...rest} />
      </LocalizationProvider>
    );
  },
);

DateFormField.displayName = 'DateFormField';
