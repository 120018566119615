import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BaseMatter,
  CreateMatterRequest,
  DetailedMatter,
  MatterKeyConsideration,
  MatterSummary,
  MatterUpdate,
} from '../types/client-intake-types';
import { FormField } from '../types/form-types';
import { MatterClient } from '../types/matter-client-types';
import {
  MatterDetailsBase,
  MatterDetailsUpdate,
} from '../types/matter-details.types';
import { RecordsRequest } from '../types/matter-records-request';
import { baseQueryWithErrorHandling } from './baseQuery';

export const mattersApiClient = createApi({
  reducerPath: 'matterApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Matter', 'Matters', 'RecordsRequest'] as const,
  endpoints: (builder) => ({
    getMatterDetails: builder.query<DetailedMatter, string>({
      query: (matterId) => `api/matters/case_details/${matterId}/`,
      transformResponse: (response: DetailedMatter): DetailedMatter => ({
        ...response,
        summaries: response.summaries.sort((a, b) =>
          a.category.localeCompare(b.category),
        ),
      }),
      providesTags: (result, error, matterId) => [
        { type: 'Matter', id: matterId },
      ],
    }),
    getMattersForUser: builder.query<BaseMatter[], void>({
      query: () => `api/matters/firm_user/`,
      providesTags: ['Matters'],
    }),
    createMatter: builder.mutation<
      { matter: DetailedMatter },
      CreateMatterRequest
    >({
      query: (body) => ({
        url: `api/matters/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Matters'],
    }),
    patchMatter: builder.mutation<
      MatterUpdate,
      { matter_id: string; patch: MatterUpdate }
    >({
      query: ({ matter_id, patch }) => ({
        url: `api/matters/${matter_id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matters' },
        { type: 'Matter', id: matter_id },
      ],
    }),
    updateFormField: builder.mutation<
      FormField,
      { matter_id: string; id: string; patch: Partial<FormField> }
    >({
      query: ({ matter_id, id, patch }) => ({
        url: `api/matters/${matter_id}/formfields/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),

    // Matter Key Considerations
    createMatterKeyConsideration: builder.mutation<
      MatterKeyConsideration,
      { matter_id: string; body: Omit<MatterKeyConsideration, 'id'> }
    >({
      query: ({ matter_id, body }) => ({
        url: `api/matters/${matter_id}/key_considerations/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),
    patchMatterKeyConsideration: builder.mutation<
      MatterKeyConsideration,
      { matter_id: string; id: string; patch: Partial<MatterKeyConsideration> }
    >({
      query: ({ matter_id, id, patch }) => ({
        url: `api/matters/${matter_id}/key_considerations/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),
    deleteMatterKeyConsideration: builder.mutation<
      void,
      { matter_id: string; id: string }
    >({
      query: ({ matter_id, id }) => ({
        url: `api/matters/${matter_id}/key_considerations/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),

    // Matter Summaries

    createMatterSummary: builder.mutation<
      MatterSummary,
      { matter_id: string; body: MatterSummary }
    >({
      query: ({ matter_id, body }) => ({
        url: `api/matters/${matter_id}/summaries/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),

    patchMatterSummary: builder.mutation<
      MatterSummary,
      { matter_id: string; id: string; patch: Partial<MatterSummary> }
    >({
      query: ({ matter_id, id, patch }) => ({
        url: `api/matters/${matter_id}/summaries/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),
    deleteMatterSummary: builder.mutation<
      void,
      { matter_id: string; id: string }
    >({
      query: ({ matter_id, id }) => ({
        url: `api/matters/${matter_id}/summaries/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),
    patchMatterClient: builder.mutation<
      MatterClient,
      {
        matter_id: string;
        matter_client_id: string;
        patch: Partial<MatterClient>;
      }
    >({
      query: ({ matter_id, matter_client_id, patch }) => ({
        url: `api/matters/${matter_id}/client/${matter_client_id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),
    patchMatterDetails: builder.mutation<
      MatterDetailsBase | undefined,
      { matter_id: string; patch: MatterDetailsUpdate }
    >({
      query: ({ matter_id, patch }) => ({
        url: `api/matters/${matter_id}/details/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'Matter', id: matter_id },
      ],
    }),
    getMatterMedicalRecordsRequest: builder.query<
      RecordsRequest | null,
      { matter_id: string }
    >({
      query: ({ matter_id }) =>
        `api/matters/${matter_id}/records_request_alpha/`,
      transformResponse: (response: RecordsRequest, meta) => {
        if (meta?.response?.status === 204) {
          return null;
        }
        return response;
      },
      providesTags: (result, error, { matter_id }) => [
        { type: 'RecordsRequest', id: matter_id },
      ],
    }),
    createMatterMedicalRecordsRequest: builder.mutation<
      RecordsRequest,
      { matter_id: string; body: Pick<RecordsRequest, 'request'> }
    >({
      query: ({ matter_id, body }) => ({
        url: `api/matters/${matter_id}/records_request_alpha/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'RecordsRequest', id: matter_id },
      ],
    }),
  }),
});

export const {
  useGetMatterDetailsQuery,
  useGetMattersForUserQuery,
  useUpdateFormFieldMutation,
  usePatchMatterMutation,
  useCreateMatterMutation,
  useCreateMatterKeyConsiderationMutation,
  usePatchMatterKeyConsiderationMutation,
  useDeleteMatterKeyConsiderationMutation,
  useCreateMatterSummaryMutation,
  usePatchMatterSummaryMutation,
  useDeleteMatterSummaryMutation,
  usePatchMatterClientMutation,
  usePatchMatterDetailsMutation,
  useCreateMatterMedicalRecordsRequestMutation,
  useGetMatterMedicalRecordsRequestQuery,
} = mattersApiClient;
