import { useEffect, useState } from 'react';
import {
  DocumentTypes,
  useGetDocumentPreviewQuery,
} from '../../services/api/documentService';
import { PDFPreview } from '../base/PDFPreview';

const PDF_PREVIEW_HEIGHT = 'calc(100vh - 200px)';

export const DocumentPreview = ({
  documentId,
  matterId,
  documentType,
}: {
  documentId: string;
  matterId: string;
  documentType: DocumentTypes;
}) => {
  const [pollingInterval, setPollingInterval] = useState(1500);
  const {
    data: pdfBlob,
    isError,
    isLoading,
  } = useGetDocumentPreviewQuery(
    {
      matterId,
      documentInstanceId: documentId,
      documentType,
    },
    { pollingInterval },
  );

  useEffect(() => {
    if (isError || isLoading) {
      setPollingInterval(pollingInterval);
    } else {
      setPollingInterval(0);
    }
  }, [isError, isLoading, pollingInterval]);

  return (
    <PDFPreview
      blob={pdfBlob ?? null}
      width="100%"
      height={PDF_PREVIEW_HEIGHT}
    />
  );
};
