import { User } from './user-types';

export enum RecordsRequestStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface RecordsRequest {
  requested_by: User;
  request: string;
  status: RecordsRequestStatus;
}
