import { Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DocumentTypes,
  useCreateDocumentMutation,
  useGetDocumentFieldsQuery,
} from '../../services/api/documentService';
import { EngagementLetterField } from '../../services/types/document-types';
import { documentConfig } from '../../utils/document-config';
import { getHumanReadableFieldName } from '../../utils/engagement-letter';
import { SecondaryButton } from '../base/Buttons';
import { useNotification } from '../contexts/NotificationContext';

const DocumentEmailInput = ({
  defaultValue,
  onChange,
}: {
  defaultValue: string;
  onChange: (fieldId: string, value: string) => void;
}) => {
  return (
    <TextField
      label="Email"
      id="email"
      name="email"
      autoComplete="email"
      defaultValue={defaultValue}
      onChange={(e) => onChange('client_email_address', e.target.value)}
      inputProps={{
        pattern: '[^\\s@]+@[^\\s@]+\\.[^\\s@]+',
      }}
    />
  );
};

const DocumentFieldInput = ({
  field,
  onChange,
}: {
  field: EngagementLetterField;
  onChange: (fieldId: string, value: string) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(field.display_name, e.target.value);
  };

  switch (field.display_name) {
    case 'client_email_address':
      return (
        <DocumentEmailInput
          defaultValue={field.default_value ?? ''}
          onChange={onChange}
        />
      );
    default:
      return (
        <TextField
          label={getHumanReadableFieldName(field.display_name)}
          onChange={handleChange}
          value={field.default_value ?? ''}
        />
      );
  }
};

const useDocumentFields = (
  matterId: string,
  documentType: DocumentTypes,
  setFormData: (formData: Record<string, string>) => void,
) => {
  const [documentFields, setDocumentFields] = useState<EngagementLetterField[]>(
    [],
  );
  const { data: fetchedDocumentFields, isLoading } = useGetDocumentFieldsQuery(
    {
      matterId,
      documentType,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (fetchedDocumentFields) {
      setDocumentFields(fetchedDocumentFields);
      // Initialize form data with default values
      const initialFormData: Record<string, string> = {};
      fetchedDocumentFields.forEach((field) => {
        if (field.default_value !== null) {
          initialFormData[field.display_name] = field.default_value;
        }
      });
      setFormData(initialFormData);
    }
  }, [fetchedDocumentFields, setFormData]);
  return { documentFields, isLoading };
};

const CreateDocumentForm = ({
  matterId,
  documentType,
  formData,
  setFormData,
}: {
  matterId: string;
  documentType: DocumentTypes;
  formData: Record<string, string>;
  setFormData: (formData: Record<string, string>) => void;
}) => {
  const { documentFields, isLoading } = useDocumentFields(
    matterId,
    documentType,
    setFormData,
  );

  const handleFormDataChange = (fieldId: string, value: string) => {
    setFormData({ ...formData, [fieldId]: value });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="column" gap={4}>
      {documentFields?.map((field) => (
        <DocumentFieldInput
          key={field.id}
          field={{
            ...field,
            default_value: formData[field.display_name] ?? field.default_value,
          }}
          onChange={handleFormDataChange}
        />
      ))}
    </Stack>
  );
};

export const CreateDocument = ({
  matterId,
  documentType,
  setOpen,
}: {
  matterId: string;
  documentType: DocumentTypes;
  setOpen: (open: boolean) => void;
}) => {
  const notify = useNotification();
  const config = documentConfig[documentType];
  const [
    createDocument,
    { isLoading: isCreatingDocument, isError, isSuccess },
  ] = useCreateDocumentMutation();

  const [formData, setFormData] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isSuccess) {
      notify(config.createSuccessMessage, 'success', 3000);
      setOpen(false);
    }
    if (isError) {
      notify(config.createErrorMessage, 'error', 3000);
    }
  }, [isSuccess, isError, notify, setOpen, config]);

  const handleSubmit = async () => {
    await createDocument({
      matterId,
      fields: formData,
      documentType,
    });
  };

  return (
    <Stack direction="column" gap={4}>
      <CreateDocumentForm
        matterId={matterId}
        documentType={documentType}
        formData={formData}
        setFormData={setFormData}
      />
      <SecondaryButton onClick={handleSubmit} disabled={isCreatingDocument}>
        {config.createButtonText}
      </SecondaryButton>
    </Stack>
  );
};
