import { useState } from 'react';

import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import {
  DocumentTypes,
  useGetRecentDocumentQuery,
  useResendDocumentMutation,
} from '../../services/api/documentService';
import { documentConfig } from '../../utils/document-config';
import { SecondaryButton } from '../base/Buttons';
import { Modal } from '../base/Modal';
import { useNotification } from '../contexts/NotificationContext';
import { CreateDocument } from './CreateDocument';
import { DownloadDocument } from './DownloadDocument';
import { SendDocument } from './SendDocument';

// Create Document
const CreateDocumentModal = ({
  open,
  setOpen,
  matterId,
  documentType,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  matterId: string;
  documentType: DocumentTypes;
}) => {
  const config = documentConfig[documentType];
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">{config.createModalTitle}</Typography>
        <Divider />
        <CreateDocument
          matterId={matterId}
          documentType={documentType}
          setOpen={setOpen}
        />
      </Stack>
    </Modal>
  );
};

const CreateDocumentButton = ({
  matterId,
  documentType,
}: {
  matterId: string;
  documentType: DocumentTypes;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const config = documentConfig[documentType];

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SecondaryButton onClick={handleOpen}>
        {config.createButtonText}
      </SecondaryButton>
      <CreateDocumentModal
        open={isOpen}
        setOpen={setIsOpen}
        matterId={matterId}
        documentType={documentType}
      />
    </>
  );
};

// Send Document
const SendDocumentModal = ({
  open,
  setOpen,
  matterId,
  documentId,
  documentType,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  matterId: string;
  documentId: string;
  documentType: DocumentTypes;
}) => {
  return (
    <Modal width={800} open={open} onClose={() => setOpen(false)}>
      <SendDocument
        setOpen={setOpen}
        documentId={documentId}
        matterId={matterId}
        documentType={documentType}
      />
    </Modal>
  );
};

const SendDocumentButton = ({
  documentId,
  matterId,
  documentType,
}: {
  documentId: string;
  matterId: string;
  documentType: DocumentTypes;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const config = documentConfig[documentType];

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SecondaryButton onClick={handleOpen}>
        {config.sendButtonText}
      </SecondaryButton>
      <SendDocumentModal
        open={isOpen}
        setOpen={setIsOpen}
        matterId={matterId}
        documentId={documentId}
        documentType={documentType}
      />
    </>
  );
};

// Resend Document
const ResendDocumentButton = ({
  matterId,
  documentId,
  documentType,
}: {
  matterId: string;
  documentId: string;
  documentType: DocumentTypes;
}) => {
  const [resendDocument, { isLoading }] = useResendDocumentMutation();
  const config = documentConfig[documentType];
  const notify = useNotification();

  const handleResend = async () => {
    try {
      await resendDocument({
        matterId,
        documentId,
        documentType,
      });
      notify(config.resendSuccessMessage, 'success', 3000);
    } catch (error) {
      console.error(error);
      notify(config.resendErrorMessage, 'error', 3000);
    }
  };

  return (
    <SecondaryButton disabled={isLoading} onClick={handleResend}>
      {config.resendButtonText}
    </SecondaryButton>
  );
};

const DownloadDocumentModal = ({
  open,
  setOpen,
  documentId,
  matterId,
  documentType,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  documentId: string;
  matterId: string;
  documentType: DocumentTypes;
}) => {
  return (
    <Modal width={800} open={open} onClose={() => setOpen(false)}>
      <DownloadDocument
        documentId={documentId}
        matterId={matterId}
        documentType={documentType}
      />
    </Modal>
  );
};

export const DownloadDocumentButton = ({
  documentId,
  matterId,
  documentType,
}: {
  documentId: string;
  matterId: string;
  documentType: DocumentTypes;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const config = documentConfig[documentType];

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SecondaryButton onClick={handleOpen}>
        {config.downloadButtonText}
      </SecondaryButton>
      <DownloadDocumentModal
        open={isOpen}
        setOpen={setIsOpen}
        documentId={documentId}
        matterId={matterId}
        documentType={documentType}
      />
    </>
  );
};

// Document Button
export const DocumentButton = ({
  matterId,
  documentType,
  mode,
}: {
  matterId: string;
  documentType: DocumentTypes;
  mode: 'Contract' | 'DocGen';
}) => {
  const {
    data: recentDocument,
    isLoading,
    isError,
  } = useGetRecentDocumentQuery({
    matterId,
    documentType,
  });

  if (isLoading) {
    return <Skeleton variant="rectangular" height={54} width={200} />;
  }

  const buttonProps = {
    documentId: recentDocument?.id,
    matterId,
    documentType,
  };

  const document = isError ? null : recentDocument;

  switch (document?.status) {
    case 'CREATED':
      if (mode === 'Contract') {
        return (
          <SendDocumentButton
            documentId={buttonProps.documentId!}
            matterId={buttonProps.matterId}
            documentType={buttonProps.documentType}
          />
        );
      }
      if (mode === 'DocGen') {
        return (
          <DownloadDocumentButton
            documentId={buttonProps.documentId!}
            matterId={buttonProps.matterId}
            documentType={buttonProps.documentType}
          />
        );
      }
      return null;
    case 'SENT':
      return (
        <ResendDocumentButton
          documentId={buttonProps.documentId!}
          matterId={buttonProps.matterId}
          documentType={buttonProps.documentType}
        />
      );
    default:
      return (
        <CreateDocumentButton
          matterId={matterId}
          documentType={buttonProps.documentType}
        />
      );
  }
};
