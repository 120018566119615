import { DocumentTypes } from '../services/api/documentService';

interface DocumentConfig {
  createButtonText: string;
  createModalTitle: string;
  createSuccessMessage: string;
  createErrorMessage: string;
  sendButtonText: string;
  sendModalTitle: string;
  sendSuccessMessage: string;
  sendErrorMessage: string;
  resendButtonText: string;
  resendSuccessMessage: string;
  resendErrorMessage: string;
  previewTitle: string;
  downloadButtonText: string;
}

export const documentConfig: Record<DocumentTypes, DocumentConfig> = {
  [DocumentTypes.ENGAGEMENT_LETTER]: {
    createButtonText: 'Create Retainer',
    createModalTitle: 'Create Engagement Letter',
    createSuccessMessage: 'Engagement letter created',
    createErrorMessage: 'Error creating engagement letter',
    sendButtonText: 'Send Engagement Letter',
    sendModalTitle: 'Send Engagement Letter',
    sendSuccessMessage: 'Engagement letter sent',
    sendErrorMessage: 'Failed to send engagement letter',
    resendButtonText: 'Resend Engagement Letter',
    resendSuccessMessage: 'Engagement letter resent successfully',
    resendErrorMessage: 'Failed to resend engagement letter',
    previewTitle: 'Engagement Letter Preview',
    downloadButtonText: 'Download Engagement Letter',
  },
  [DocumentTypes.LETTER_OF_REPRESENTATION]: {
    createButtonText: 'Create Letter of Representation',
    createModalTitle: 'Create Letter of Representation',
    createSuccessMessage: 'Letter of representation created',
    createErrorMessage: 'Error creating letter of representation',
    sendButtonText: 'Send Letter of Representation',
    sendModalTitle: 'Send Letter of Representation',
    sendSuccessMessage: 'Letter of representation sent',
    sendErrorMessage: 'Failed to send letter of representation',
    resendButtonText: 'Resend Letter of Representation',
    resendSuccessMessage: 'Letter of representation resent successfully',
    resendErrorMessage: 'Failed to resend letter of representation',
    previewTitle: 'Letter of Representation Preview',
    downloadButtonText: 'Download Letter of Representation',
  },
  [DocumentTypes.FIRST_PARTY_LETTER_OF_REPRESENTATION]: {
    createButtonText: 'Create First Party LOR',
    createModalTitle: 'Create First Party Letter of Representation',
    createSuccessMessage: 'First party letter of representation created',
    createErrorMessage: 'Error creating first party letter of representation',
    sendButtonText: 'Send First Party LOR',
    sendModalTitle: 'Send First Party Letter of Representation',
    sendSuccessMessage: 'First party letter of representation sent',
    sendErrorMessage: 'Failed to send first party letter of representation',
    resendButtonText: 'Resend First Party LOR',
    resendSuccessMessage:
      'First party letter of representation resent successfully',
    resendErrorMessage: 'Failed to resend first party letter of representation',
    previewTitle: 'First Party Letter of Representation Preview',
    downloadButtonText: 'Download First Party LOR',
  },
  [DocumentTypes.THIRD_PARTY_LETTER_OF_REPRESENTATION]: {
    createButtonText: 'Create Third Party LOR',
    createModalTitle: 'Create Third Party Letter of Representation',
    createSuccessMessage: 'Third party letter of representation created',
    createErrorMessage: 'Error creating third party letter of representation',
    sendButtonText: 'Send Third Party LOR',
    sendModalTitle: 'Send Third Party Letter of Representation',
    sendSuccessMessage: 'Third party letter of representation sent',
    sendErrorMessage: 'Failed to send third party letter of representation',
    resendButtonText: 'Resend Third Party LOR',
    resendSuccessMessage:
      'Third party letter of representation resent successfully',
    resendErrorMessage: 'Failed to resend third party letter of representation',
    previewTitle: 'Third Party Letter of Representation Preview',
    downloadButtonText: 'Download Third Party LOR',
  },
};
