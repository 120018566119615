import { Box, Grid, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types/form-types';
import { getSubpath } from '../../../utils/react-hook-form';
import {
  ControlledDateField,
  ControlledPhoneField,
} from '../../base/forms/ControlledFields';
import { EmailFormField } from '../../base/forms/EmailFormField';
import { TextFormField } from '../../base/forms/TextFormField';
import { AddressForm } from './AddressForm';
import { VehicleForm } from './VehicleForm';

export const AutoInsuranceContactForm = ({
  labelPrefix,
  path = '',
  spacing = 2,
}: FormProps & { spacing?: number }) => {
  const form = useFormContext();

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12}>
        <TextFormField
          label={`${labelPrefix}Name`}
          {...form.register(getSubpath(path, 'name'))}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledPhoneField
          name={getSubpath(path, 'phone_number')}
          control={form.control}
          label={`${labelPrefix}Phone Number`}
        />
      </Grid>
      <Grid item xs={6}>
        <EmailFormField
          label={`${labelPrefix}Email`}
          {...form.register(getSubpath(path, 'email'))}
        />
      </Grid>
    </Grid>
  );
};

export const AutoInsurancePolicyHolderForm = ({
  path,
  labelPrefix,
  spacing = 2,
}: FormProps & { spacing?: number }) => {
  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12}>
        <AutoInsuranceContactForm
          labelPrefix={labelPrefix}
          path={getSubpath(path, 'contact')}
          spacing={spacing}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressForm
          labelPrefix={`${labelPrefix} Address`}
          path={getSubpath(path, 'address')}
          spacing={spacing}
        />
      </Grid>
    </Grid>
  );
};

export const AutoInsuranceClaimForm = ({
  path,
  labelPrefix,
  spacing = 2,
}: FormProps & { spacing?: number }) => {
  const form = useFormContext();

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={6}>
        <TextFormField
          label={`${labelPrefix} Claim Number`}
          {...form.register(getSubpath(path, 'claim_number'))}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledDateField
          name={getSubpath(path, 'date_filed')}
          control={form.control}
          label={`${labelPrefix} Date Filed`}
        />
      </Grid>
    </Grid>
  );
};

export const AutoInsurancePolicyForm = ({
  path,
  labelPrefix,
  spacing = 2,
}: FormProps & { spacing?: number }) => {
  const form = useFormContext();

  return (
    <Grid container spacing={spacing}>
      {/* Insurance Company Details */}
      <Grid item xs={12}>
        <TextFormField
          label={`${labelPrefix} Insurance Company`}
          {...form.register(getSubpath(path, 'insurance_company'))}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressForm
          labelPrefix={`${labelPrefix} Address`}
          path={getSubpath(path, 'insurance_company_address')}
          spacing={spacing}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Policy Number`}
          {...form.register(getSubpath(path, 'policy_number'))}
        />
      </Grid>
      <Grid item xs={8}>
        <TextFormField
          multiline
          label={`${labelPrefix} Policy Limitations`}
          {...form.register(getSubpath(path, 'policy_limitations'))}
        />
      </Grid>
    </Grid>
  );
};

interface InsuranceFormProps extends FormProps {
  spacing?: number;
}

export const InsuranceForm = ({
  path,
  labelPrefix,
  spacing = 4,
}: InsuranceFormProps) => {
  return (
    <Stack spacing={spacing}>
      <Typography variant="h3">Policy Information</Typography>
      <Box>
        <AutoInsurancePolicyForm
          path={path}
          labelPrefix={labelPrefix}
          spacing={spacing}
        />
      </Box>
      <Typography variant="h3">Policy Holder</Typography>
      <Box>
        <AutoInsurancePolicyHolderForm
          path={getSubpath(path, 'external_holder')}
          labelPrefix={labelPrefix}
          spacing={spacing}
        />
      </Box>
      <Typography variant="h3">Vehicle</Typography>
      <Box>
        <VehicleForm
          path={getSubpath(path, 'vehicle')}
          labelPrefix={labelPrefix}
          spacing={spacing}
        />
      </Box>
      <Typography variant="h3">Claim</Typography>
      <Box>
        <AutoInsuranceClaimForm
          path={getSubpath(path, 'claim')}
          labelPrefix={labelPrefix}
          spacing={spacing}
        />
      </Box>
      <Box>
        <AutoInsuranceContactForm
          path={getSubpath(path, 'claim.adjuster')}
          labelPrefix="Claim Adjuster "
          spacing={spacing}
        />
      </Box>
    </Stack>
  );
};
