import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  useCreateMatterMedicalRecordsRequestMutation,
  useGetMatterMedicalRecordsRequestQuery,
} from '../../services/api/mattersService';
import { RecordsRequestStatus } from '../../services/types/matter-records-request';

const DEFAULT_REQUEST_TEXT = `Please provide the following records:
- Medical records
- ER records
`;

interface CreateRequestModalProps {
  open: boolean;
  onClose: () => void;
  matterId: string;
}

const CreateRequestModal = ({
  open,
  onClose,
  matterId,
}: CreateRequestModalProps) => {
  const [requestText, setRequestText] = useState(DEFAULT_REQUEST_TEXT);
  const [createRequest] = useCreateMatterMedicalRecordsRequestMutation();

  const handleSubmit = async () => {
    try {
      await createRequest({
        matter_id: matterId,
        body: {
          request: requestText,
        },
      }).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to create records request:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Records Request</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Request Details"
            multiline
            rows={6}
            value={requestText}
            onChange={(e) => setRequestText(e.target.value)}
            fullWidth
          />
          <Typography variant="body2">
            By submitting this request, you understand that Finch will attempt
            to retrieve the records on your behalf.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface RecordsRequestButtonProps {
  matterId: string;
}

export const RecordsRequestButton = ({
  matterId,
}: RecordsRequestButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: recordsRequest } = useGetMatterMedicalRecordsRequestQuery({
    matter_id: matterId,
  });

  const handleCreateRequest = () => {
    setIsModalOpen(true);
  };

  if (recordsRequest?.status === RecordsRequestStatus.PENDING) {
    return (
      <Button variant="contained" disabled>
        Records Requested
      </Button>
    );
  }

  return (
    <>
      <Button variant="contained" onClick={handleCreateRequest}>
        Create Record Request
      </Button>
      <CreateRequestModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        matterId={matterId}
      />
    </>
  );
};
