//  PI Form
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types/form-types';
import { getSubpath } from '../../../utils/react-hook-form';
import {
  ControlledDateField,
  ControlledSliderField,
  ControlledTimeField,
} from '../../base/forms/ControlledFields';
import { TextFormField } from '../../base/forms/TextFormField';

export const MatterDetailsPIForm = ({ path, labelPrefix }: FormProps) => {
  const form = useFormContext();

  return (
    <Grid container spacing={4}>
      {/* Basic accident information */}
      <Grid item xs={4}>
        <TextFormField
          {...form.register('location')}
          label={`${labelPrefix} Location`}
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledDateField
          name={getSubpath(path, 'date_of_loss')}
          control={form.control}
          label={`${labelPrefix} Date of Loss`}
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledTimeField
          name={getSubpath(path, 'time_of_loss')}
          control={form.control}
          label={`${labelPrefix} Time of Loss`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          label={`${labelPrefix} Description`}
          {...form.register(getSubpath(path, 'description'))}
          multiline
          rows={4}
        />
      </Grid>

      {/* Injury information */}
      <Grid item xs={12}>
        <TextFormField
          label={`${labelPrefix} Injuries & Complaints`}
          {...form.register(getSubpath(path, 'injuries_complaints'))}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          {...form.register(getSubpath(path, 'hospital_er'))}
          label={`${labelPrefix} Hospital/ER`}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'substantial_injury')}
          control={form.control}
          label={`${labelPrefix} Substantial Injury`}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'permanent_injury')}
          control={form.control}
          label={`${labelPrefix} Permanent Injury`}
        />
      </Grid>

      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'ems_ambulance')}
          control={form.control}
          label={`${labelPrefix} EMS/Ambulance`}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'currently_treating')}
          control={form.control}
          label={`${labelPrefix} Currently Treating`}
        />
      </Grid>

      {/* Documentation */}
      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'police_report_filed')}
          control={form.control}
          label={`${labelPrefix} Police Report Filed`}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'has_photos')}
          control={form.control}
          label={`${labelPrefix} Has Photos`}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledSliderField
          name={getSubpath(path, 'has_witnesses')}
          control={form.control}
          label={`${labelPrefix} Has Witnesses`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          label={`${labelPrefix} Witness Statements`}
          {...form.register(getSubpath(path, 'witness_statements'))}
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );
};
