import { createApi } from '@reduxjs/toolkit/query/react';
import { EngagementLetterFields } from '../types/document-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export enum DocumentTypes {
  ENGAGEMENT_LETTER = 'ENGAGEMENT_LETTER',
  LETTER_OF_REPRESENTATION = 'LETTER_OF_REPRESENTATION',
  FIRST_PARTY_LETTER_OF_REPRESENTATION = 'FIRST_PARTY_LETTER_OF_REPRESENTATION',
  THIRD_PARTY_LETTER_OF_REPRESENTATION = 'THIRD_PARTY_LETTER_OF_REPRESENTATION',
}

const DocumentTypeToBasePath = {
  [DocumentTypes.ENGAGEMENT_LETTER]: 'engagement_letter',
  [DocumentTypes.LETTER_OF_REPRESENTATION]: 'letter_of_representation',
  [DocumentTypes.FIRST_PARTY_LETTER_OF_REPRESENTATION]:
    'first_party_letter_of_representation',
  [DocumentTypes.THIRD_PARTY_LETTER_OF_REPRESENTATION]:
    'third_party_letter_of_representation',
};

// TODO joanie switch back to these from fetch APIs
export const documentApiClient = createApi({
  reducerPath: 'documentApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['TranscriptPdf', 'DocumentPdf', 'FormPdf', 'Document'] as const,
  endpoints: (builder) => ({
    getTranscriptPdf: builder.query<unknown, string>({
      query: (conversationId) => `api/transcript/${conversationId}/pdf/`,
      providesTags: (result, error, conversationId) => [
        { type: 'TranscriptPdf', id: conversationId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
    getDocumentFields: builder.query<
      EngagementLetterFields,
      { matterId: string; documentType: DocumentTypes }
    >({
      query: ({ matterId, documentType }) => ({
        url: `api/matters/${matterId}/${DocumentTypeToBasePath[documentType]}_fields`,
      }),
    }),
    getRecentDocument: builder.query<
      { id: string; status: 'SENT' | 'CREATED' | 'COMPLETED' | 'VOID' },
      { matterId: string; documentType: DocumentTypes }
    >({
      query: ({ matterId, documentType }) => ({
        url: `api/matters/${matterId}/${DocumentTypeToBasePath[documentType]}/recent`,
      }),
      providesTags: (result, error, { matterId, documentType }) => [
        { type: 'Document', id: `${matterId}-${documentType}` },
      ],
    }),
    getDocumentPreview: builder.query<
      Blob,
      {
        matterId: string;
        documentInstanceId: string;
        documentType: DocumentTypes;
      }
    >({
      query: ({ matterId, documentInstanceId, documentType }) => ({
        url: `api/matters/${matterId}/${DocumentTypeToBasePath[documentType]}/${documentInstanceId}/preview`,
        responseHandler: (response) => response.blob(),
      }),
    }),
    createDocument: builder.mutation<
      unknown,
      {
        matterId: string;
        fields: Record<string, string>;
        documentType: DocumentTypes;
      }
    >({
      query: ({ matterId, fields, documentType }) => ({
        url: `api/matters/${matterId}/${DocumentTypeToBasePath[documentType]}`,
        method: 'POST',
        body: fields,
      }),
      invalidatesTags: (result, error, { matterId, documentType }) => [
        { type: 'Document', id: `${matterId}-${documentType}` },
      ],
    }),
    sendDocument: builder.mutation<
      { document_instance_id: string },
      { matterId: string; documentId: string; documentType: DocumentTypes }
    >({
      query: ({ matterId, documentId, documentType }) => ({
        url: `api/matters/${matterId}/${DocumentTypeToBasePath[documentType]}/${documentId}/send`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { matterId, documentType }) => [
        { type: 'Document', id: `${matterId}-${documentType}` },
      ],
    }),
    resendDocument: builder.mutation<
      {
        document_instance_id: string;
        agreement_id: string;
      },
      { matterId: string; documentId: string; documentType: DocumentTypes }
    >({
      query: ({ matterId, documentId, documentType }) => ({
        url: `api/matters/${matterId}/${DocumentTypeToBasePath[documentType]}/${documentId}/re_send`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { matterId, documentType }) => [
        { type: 'Document', id: `${matterId}-${documentType}` },
      ],
    }),
  }),
});

export const {
  useGetTranscriptPdfQuery,
  useGetDocumentFieldsQuery,
  useGetRecentDocumentQuery,
  useGetDocumentPreviewQuery,
  useCreateDocumentMutation,
  useSendDocumentMutation,
  useResendDocumentMutation,
} = documentApiClient;
