import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { usePatchMatterClientMutation } from '../../../services/api/mattersService';
import { MatterClient } from '../../../services/types/matter-client-types';
import {
  ControlledDateField,
  ControlledPhoneField,
} from '../../base/forms/ControlledFields';
import { EmailFormField } from '../../base/forms/EmailFormField';
import { TextFormField } from '../../base/forms/TextFormField';
import { useNotification } from '../../contexts/NotificationContext';
import { AddressForm } from '../../matter-details/forms/AddressForm';

export const MatterClientForm = ({ spacing = 3 }: { spacing?: number }) => {
  const form = useFormContext();
  const {
    fields: phoneNumberFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'phone_numbers',
  });

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={4}>
        <TextFormField label="Name" {...form.register('name')} />
      </Grid>
      <Grid item xs={4}>
        <EmailFormField label="Email" {...form.register('email')} />
      </Grid>
      <Grid item xs={4}>
        <ControlledDateField
          name="date_of_birth"
          control={form.control}
          label="Date of Birth"
        />
      </Grid>
      <Box sx={{ px: spacing, pt: spacing, width: '100%' }}>
        <Grid item xs={12}>
          <Grid container spacing={spacing}>
            {phoneNumberFields.map((field, index) => (
              <Grid item xs={3} key={field.id}>
                <ControlledPhoneField
                  name={`phone_numbers.${index}.phone_number`}
                  control={form.control}
                  label={`Phone Number ${index + 1}`}
                  onDelete={() => remove(index)}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                startIcon={<AddIcon />}
                onClick={() => append({ id: '', phone_number: '' })}
                variant="outlined"
              >
                Add Phone Number
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid item xs={12}>
        <AddressForm path="address" labelPrefix="" spacing={spacing} />
      </Grid>
    </Grid>
  );
};

interface MatterClientProps {
  client: MatterClient;
}

export const MatterClientView = ({ client }: MatterClientProps) => {
  const { matter_id } = useParams();
  const notify = useNotification();
  const [patchMatterClient] = usePatchMatterClientMutation();

  const form = useForm({
    defaultValues: client,
    mode: 'onChange',
  });

  const handleSubmit = async (data: MatterClient) => {
    if (await form.trigger()) {
      try {
        await patchMatterClient({
          matter_id: matter_id!,
          matter_client_id: client.id,
          patch: data,
        }).unwrap();
        notify('Client updated successfully', 'success', 3000);
        form.reset(data);
      } catch (error) {
        notify('Failed to update client', 'error', 3000);
      }
    }
  };

  return (
    <FormProvider {...form}>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h2">Client Information</Typography>
          <Button
            variant="contained"
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Save
          </Button>
        </Stack>
        <MatterClientForm />
      </Stack>
    </FormProvider>
  );
};
