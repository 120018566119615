export const isPhoneNumberValid = (phoneNumber: string) => {
  // Accepting flexible phone number formats like (303) 217-0796 or 303-217-0796
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phonePattern.test(phoneNumber);
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
  return `+1${phoneNumber.replace(/\D/g, '')}`;
};

export const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isEmailValid = (email: string) => {
  return EmailRegex.test(email);
};
export const normalizePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  // Remove +1 prefix if it exists
  return phoneNumber.replace('+1', '');
};
