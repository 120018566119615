import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types/form-types';
import { getSubpath } from '../../../utils/react-hook-form';
import { TextFormField } from '../../base/forms/TextFormField';

export const VehicleForm = ({
  labelPrefix,
  path = '',
  spacing = 2,
}: FormProps & { spacing?: number }) => {
  const form = useFormContext();

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Make`}
          {...form.register(getSubpath(path, 'make'))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Model`}
          {...form.register(getSubpath(path, 'model'))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Year`}
          {...form.register(getSubpath(path, 'year'))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} VIN`}
          {...form.register(getSubpath(path, 'vin'))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} License Plate`}
          {...form.register(getSubpath(path, 'license_plate'))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} State`}
          {...form.register(getSubpath(path, 'state'))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} Country`}
          {...form.register(getSubpath(path, 'country'))}
        />
      </Grid>
    </Grid>
  );
};
