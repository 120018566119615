import {
  Button,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateMatterKeyConsiderationMutation } from '../../../services/api';
import {
  DetailedMatter,
  MatterKeyConsideration,
  MatterKeyConsiderationCategory,
  MatterKeyConsiderationSeverities,
  MatterKeyConsiderationSeverity,
} from '../../../services/types/client-intake-types';
import { SecondaryButton } from '../../base/Buttons';
import { useNotification } from '../../contexts/NotificationContext';
import { KeyConsiderationFlag } from './KeyConsiderationFlag';

const FlagSelector = ({
  severity,
  onChange,
}: {
  severity: MatterKeyConsiderationSeverity;
  onChange: (severity: MatterKeyConsiderationSeverity) => void;
}) => {
  return <KeyConsiderationFlag severity={severity} onChange={onChange} />;
};

const CategorySelector = ({
  category,
  matter,
  onChange,
}: {
  category: MatterKeyConsiderationCategory;
  matter: DetailedMatter;
  onChange: (category: MatterKeyConsiderationCategory) => void;
}) => {
  const existingCategories = new Set(
    matter.key_considerations.map((kc) => kc.category),
  );
  const remainingCategories = Object.values(
    MatterKeyConsiderationCategory,
  ).filter((value) => !existingCategories.has(value));

  return (
    <Select
      fullWidth
      value={category}
      onChange={(e) =>
        onChange(e.target.value as MatterKeyConsiderationCategory)
      }
      displayEmpty
    >
      {remainingCategories.map((c) => (
        <MenuItem key={`create-category-${c}`} value={c}>
          {c}
        </MenuItem>
      ))}
    </Select>
  );
};

const ContentField = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <TextField
      fullWidth
      label="Description"
      margin="normal"
      variant="outlined"
      value={value}
      multiline
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export const KeyConsiderationForm: React.FC<{
  matter: DetailedMatter;
  onClose: () => void;
}> = ({ matter, onClose }) => {
  const [keyConsideration, setKeyConsideration] = useState<
    Omit<MatterKeyConsideration, 'id'>
  >({
    category: MatterKeyConsiderationCategory.CLEAR_LIABILITY,
    severity: MatterKeyConsiderationSeverities[0],
    content: '',
  });

  const [createKeyConsideration, { isLoading, isError, isSuccess }] =
    useCreateMatterKeyConsiderationMutation();

  const notify = useNotification();

  const handleCreate = () => {
    createKeyConsideration({
      matter_id: matter.id,
      body: keyConsideration,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      notify('Key consideration created', 'success', 3000);
      onClose();
    }
  }, [isSuccess, notify, onClose]);

  useEffect(() => {
    if (isError) {
      notify('Failed to create key consideration', 'error', 3000);
    }
  }, [isError, notify]);

  return (
    <Stack
      spacing={4}
      p={4}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Typography variant="h2">Create Key Consideration</Typography>

      <Divider sx={{ width: '100%', my: 2 }} />

      <FlagSelector
        severity={keyConsideration.severity}
        onChange={(s) =>
          setKeyConsideration({ ...keyConsideration, severity: s })
        }
      />
      <CategorySelector
        category={keyConsideration.category}
        matter={matter}
        onChange={(c) =>
          setKeyConsideration({ ...keyConsideration, category: c })
        }
      />

      <ContentField
        value={keyConsideration.content}
        onChange={(c) =>
          setKeyConsideration({ ...keyConsideration, content: c })
        }
      />

      <Divider sx={{ width: '100%', my: 2 }} />

      <Stack direction="row" justifyContent="space-between" width="100%">
        <Button disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <SecondaryButton disabled={isLoading} onClick={handleCreate}>
          Create
        </SecondaryButton>
      </Stack>
    </Stack>
  );
};
