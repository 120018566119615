import AddIcon from '@mui/icons-material/Add';
import { Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useMatterSelection from '../../hooks/useMatterSelection';
import { useCreateMatterMutation } from '../../services/api';
import { selectUser } from '../../state/selectors/root-selectors';
import { sanitizePhoneNumber } from '../../utils/validation';
import { SecondaryButton, TertiaryButton } from '../base/Buttons';
import { ControlledPhoneField } from '../base/forms/ControlledFields';
import { TextFormField } from '../base/forms/TextFormField';
import { Modal } from '../base/Modal';
import { useNotification } from '../contexts/NotificationContext';

interface CreateMatterFormState {
  name: string;
  phoneNumber: string;
}

const CreateMatterForm: React.FC<{
  onSubmit: (data: CreateMatterFormState) => void;
}> = ({ onSubmit }) => {
  const form = useForm<CreateMatterFormState>({
    mode: 'onChange',
  });

  return (
    <Stack gap={4}>
      <ControlledPhoneField
        control={form.control}
        name="phoneNumber"
        label="Phone Number"
      />

      <TextFormField
        {...form.register('name', { required: true })}
        label="Matter Name"
      />

      <SecondaryButton
        disabled={!form.formState.isValid}
        onClick={form.handleSubmit(onSubmit)}
      >
        Submit
      </SecondaryButton>
    </Stack>
  );
};

const CreateMatterModal: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const [createMatter] = useCreateMatterMutation();
  const notify = useNotification();
  const user = useSelector(selectUser);
  const { handleMatterChange } = useMatterSelection();

  const handleSubmit = async (data: CreateMatterFormState) => {
    if (!user?.firm) {
      notify('User must be in a firm to create a matter', 'error', 3000);
      return;
    }

    const result = await createMatter({
      firm: user.firm,
      creator: user.id,
      name: data.name,
      clients: [
        {
          phone_numbers: [
            { phone_number: sanitizePhoneNumber(data.phoneNumber) },
          ],
        },
      ],
    });

    if (result.data) {
      notify(
        `Matter ${result.data.matter.name} created successfully`,
        'success',
        3000,
      );
      handleMatterChange(result.data.matter.id);
      setIsOpen(false);
    } else {
      notify(`Failed to create matter ${data.name}`, 'error', 3000);
    }
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Create Matter</Typography>
        <Divider />
        <CreateMatterForm onSubmit={handleSubmit} />
      </Stack>
    </Modal>
  );
};

export const CreateMatter: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TertiaryButton disableRipple onClick={() => setIsOpen(true)}>
        <AddIcon />
      </TertiaryButton>
      <CreateMatterModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
