//  Projection

import { Box, Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { usePatchMatterDetailsMutation } from '../../services/api/mattersService';
import { DetailedMatter } from '../../services/types/client-intake-types';
import {
  MatterDetailsBase,
  MatterDetailsMVA,
  MatterDetailsPI,
  MatterIncidentType,
} from '../../services/types/matter-details.types';
import { prepareMatterDetailsForUpdate } from '../../utils/matter-details';
import { useNotification } from '../contexts/NotificationContext';
import { InsuranceForm } from './forms/AutoInsuranceForm';
import { MatterDetailsMVAForm } from './forms/MatterDetailsMVAForm';
import { MatterDetailsPIForm } from './forms/MatterDetailsPIForm';

const useMatterDetailsForm = (matterDetails: MatterDetailsBase) => {
  const { matter_id } = useParams();
  const notify = useNotification();
  const [patchMatterDetails] = usePatchMatterDetailsMutation();
  const form = useForm<MatterDetailsBase>({ defaultValues: matterDetails });

  const handleSubmit = async (data: MatterDetailsBase) => {
    if (await form.trigger()) {
      try {
        await patchMatterDetails({
          matter_id: matter_id!,
          patch: prepareMatterDetailsForUpdate(data),
        }).unwrap();
        notify('Matter details updated successfully', 'success', 3000);
        form.reset(data);
      } catch (error) {
        notify('Failed to update matter details', 'error', 3000);
      }
    }
  };

  return { form, handleSubmit };
};

const MatterDetailsMVAContent = ({
  matterDetails,
  spacing = 3,
}: {
  matterDetails: MatterDetailsMVA;
  spacing?: number;
}) => {
  const { form, handleSubmit } = useMatterDetailsForm(matterDetails);

  return (
    <FormProvider {...form}>
      <Stack spacing={spacing}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2">Matter Details</Typography>
          <Button
            variant="contained"
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Save
          </Button>
        </Stack>
        <Box>
          <MatterDetailsPIForm path="" labelPrefix="" />
        </Box>
        <Box>
          <MatterDetailsMVAForm path="" labelPrefix="" />
        </Box>
      </Stack>
      <Stack spacing={spacing} pt={spacing}>
        <Typography variant="h2">First Party Insurance</Typography>
        <Stack spacing={spacing}>
          <InsuranceForm
            path="first_party_insurance"
            labelPrefix=""
            spacing={spacing}
          />
        </Stack>
        <Typography variant="h2">Third Party Insurance</Typography>
        <Stack spacing={spacing}>
          <InsuranceForm
            path="third_party_insurance"
            labelPrefix=""
            spacing={spacing}
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

const MatterDetailsPIContent = ({
  matterDetails,
  spacing = 4,
}: {
  matterDetails: MatterDetailsPI;
  spacing?: number;
}) => {
  const { form, handleSubmit } = useMatterDetailsForm(matterDetails);

  return (
    <FormProvider {...form}>
      <Stack spacing={spacing} px={spacing}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2">Matter Details</Typography>
          <Button
            variant="contained"
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Save
          </Button>
        </Stack>
        <Box>
          <MatterDetailsPIForm path="" labelPrefix="" />
        </Box>
      </Stack>
    </FormProvider>
  );
};

export const MatterDetailsProjection: React.FC<{
  matter: DetailedMatter;
}> = ({ matter }) => {
  if (matter.details_type === MatterIncidentType.PI_MVA) {
    return <MatterDetailsMVAContent matterDetails={matter.details} />;
  }

  if (matter.details_type === MatterIncidentType.PI_GENERAL) {
    return <MatterDetailsPIContent matterDetails={matter.details} />;
  }

  return null;
};

// Root Component

interface MatterDetailsProps {
  matter: DetailedMatter;
}

export const MatterDetailsContent: React.FC<MatterDetailsProps> = ({
  matter,
}) => {
  return (
    <Stack direction="column" gap="24px">
      <Stack mx={0}>
        <MatterDetailsProjection matter={matter} />
      </Stack>
    </Stack>
  );
};
