import { AutoInsurancePolicy } from './insurance-types';

// ---- Matter Details ----
export interface MatterDetailsBase {
  id: string;
  created_at: string;
  modified_at: string;
}

export interface MatterDetailsPI extends MatterDetailsBase {
  // Basic accident information
  location?: string;
  date_of_loss?: string;
  time_of_loss?: string;
  description?: string;

  // Injury information
  injuries_complaints?: string;
  substantial_injury?: boolean;
  permanent_injury?: boolean;
  hospital_er?: string;
  ems_ambulance?: boolean;
  currently_treating?: boolean;

  // Documentation
  police_report_filed?: boolean;
  has_witnesses?: boolean;
  witness_statements?: string;
  has_photos?: boolean;
}

export interface MatterDetailsMVA extends MatterDetailsPI {
  weather_conditions?: string;
  coming_from?: string;
  going_to?: string;
  accident_type?: string;
  first_party_insurance?: AutoInsurancePolicy;
  third_party_insurance?: AutoInsurancePolicy;
}

export enum MatterIncidentType {
  PI_MVA = 'PI_MVA',
  PI_GENERAL = 'PI_GENERAL',
  OTHER = 'OTHER',
}

export type MatterDetails = MatterDetailsPI | MatterDetailsMVA;

export type MatterDetailsUpdate = Omit<
  MatterDetails,
  'id' | 'created_at' | 'modified_at'
>;

export type DiscriminatedMatterDetails =
  | {
      details_type: undefined;
      details: undefined;
    }
  | {
      details_type: MatterIncidentType.OTHER;
      details: undefined;
    }
  | {
      details_type: MatterIncidentType.PI_GENERAL;
      details: MatterDetailsPI;
    }
  | {
      details_type: MatterIncidentType.PI_MVA;
      details: MatterDetailsMVA;
    };
