import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { forwardRef } from 'react';

interface SliderFormFieldProps extends SwitchProps {
  label: string;
}

export const SliderFormField = forwardRef<HTMLDivElement, SliderFormFieldProps>(
  (props, ref) => {
    return (
      <FormControlLabel
        ref={ref}
        control={<Switch {...props} />}
        label={props.label}
      />
    );
  },
);

SliderFormField.displayName = 'SliderFormField';
