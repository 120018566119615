interface GenericEngagementLetterField<T> {
  id: string;
  display_name: T;
  is_required: boolean;
  default_value: string | null;
}

export type PredefinedEngagementLetterField = GenericEngagementLetterField<
  keyof typeof HumanReadableDisplayNames
>;

export type CustomEngagementLetterField = GenericEngagementLetterField<string>;

export type EngagementLetterField =
  | PredefinedEngagementLetterField
  | CustomEngagementLetterField;

export type EngagementLetterFields = EngagementLetterField[];

export const HumanReadableDisplayNames = {
  accident_description: 'Accident Description',
  adjuster_name: 'Adjuster Name',
  attorney_name: 'Attorney Name',
  case_type: 'Case Type',
  claim_number: 'Claim Number',
  client_address: 'Client Address',
  client_email_address: 'Email Address',
  client_name: 'Client Name',
  date: 'Date',
  date_of_loss: 'Date of Loss',
  insurance_address: 'Insurance Address',
  insurance_company_name: 'Insurance Company Name',
  insured_name: 'Insured Name',
  policy_number: 'Policy Number',
  post_filing_contingency_fee: 'Post-filing contingency fee',
  pre_filing_contingency_fee: 'Pre-filing contingency fee',
  recipient_name: 'Recipient Name',
  your_insured: 'Your Insured',
} as const;
