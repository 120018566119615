import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types/form-types';
import { getSubpath } from '../../../utils/react-hook-form';
import { ControlledCountryField } from '../../base/forms/ControlledFields';
import { TextFormField } from '../../base/forms/TextFormField';

interface AddressFormProps extends FormProps {
  spacing?: number;
}

export const AddressForm = ({
  labelPrefix,
  path = '',
  disabled,
  spacing = 2,
}: AddressFormProps) => {
  const form = useFormContext();

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Line 1`}
          {...form.register(getSubpath(path, 'line1'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Line 2`}
          {...form.register(getSubpath(path, 'line2'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          label={`${labelPrefix} Line 3`}
          {...form.register(getSubpath(path, 'line3'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} City`}
          {...form.register(getSubpath(path, 'city'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} State`}
          {...form.register(getSubpath(path, 'state'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          label={`${labelPrefix} Postcode`}
          {...form.register(getSubpath(path, 'postcode'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledCountryField
          name={getSubpath(path, 'country')}
          control={form.control}
          label={`${labelPrefix} Country`}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
