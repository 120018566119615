import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormProps } from '../../../types/form-types';
import { getSubpath } from '../../../utils/react-hook-form';
import { TextFormField } from '../../base/forms/TextFormField';

export const MatterDetailsMVAForm = ({ path, labelPrefix }: FormProps) => {
  const form = useFormContext();

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <TextFormField
          label={`${labelPrefix} Weather Conditions`}
          {...form.register(getSubpath(path, 'weather_conditions'))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFormField
          label={`${labelPrefix} Accident Type`}
          {...form.register(getSubpath(path, 'accident_type'))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFormField
          label={`${labelPrefix} Coming From`}
          {...form.register(getSubpath(path, 'coming_from'))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFormField
          label={`${labelPrefix} Going To`}
          {...form.register(getSubpath(path, 'going_to'))}
        />
      </Grid>
    </Grid>
  );
};
