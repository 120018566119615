import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

export const PhoneNumberFormField = forwardRef<
  HTMLInputElement,
  TextFieldProps
>(({ ...rest }: TextFieldProps, ref) => {
  return (
    <TextField
      ref={ref}
      margin="none"
      required
      fullWidth
      name="phoneNumber"
      type="tel"
      id="phoneNumber"
      {...rest}
    />
  );
});

PhoneNumberFormField.displayName = 'PhoneNumberFormField';
