import { Button, Divider, Stack, Typography } from '@mui/material';
import {
  DocumentTypes,
  useSendDocumentMutation,
} from '../../services/api/documentService';
import { documentConfig } from '../../utils/document-config';
import { SecondaryButton } from '../base/Buttons';
import { useNotification } from '../contexts/NotificationContext';
import { DocumentPreview } from './DocumentPreview';

export const SendDocument = ({
  setOpen,
  matterId,
  documentId,
  documentType,
}: {
  setOpen: (open: boolean) => void;
  matterId: string;
  documentId: string;
  documentType: DocumentTypes;
}) => {
  const notify = useNotification();
  const config = documentConfig[documentType];
  const [sendDocument, { isLoading }] = useSendDocumentMutation();

  const handleSend = async () => {
    try {
      await sendDocument({
        matterId,
        documentId,
        documentType,
      });
      notify(config.sendSuccessMessage, 'success', 3000);
      setOpen(false);
    } catch (error) {
      notify(config.sendErrorMessage, 'error', 3000);
    }
  };

  return (
    <Stack
      maxHeight="90vh"
      alignItems="center"
      direction="column"
      gap={4}
      padding={4}
    >
      <Typography variant="h2">{config.previewTitle}</Typography>
      <Divider flexItem />
      <DocumentPreview
        documentId={documentId}
        matterId={matterId}
        documentType={documentType}
      />
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        px="40px"
      >
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <SecondaryButton onClick={handleSend} disabled={isLoading}>
          {config.sendButtonText}
        </SecondaryButton>
      </Stack>
    </Stack>
  );
};
