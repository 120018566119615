import { Box, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import useMatterSelection from '../../hooks/useMatterSelection';
import IntakeCallSection from '../intake-form/IntakeCallSection';
import KeyConsiderations from '../key_considerations/KeyConsiderations';
import { MatterClientView } from '../matter-client/forms/MatterClientForm';
import { MatterDetailsContent } from '../matter-details/MatterDetails';
import MatterTitle from '../MatterTitle';
import { Sidebar } from '../Sidebar';
import Summaries from '../Summaries';
import WelcomeScreen from './WelcomeScreen';

const Cms: React.FC = () => {
  const { allMatters, selectedMatterData } = useMatterSelection();

  const hasExistingMatters = allMatters && allMatters.length > 0;
  const hasKeyConsiderations =
    selectedMatterData?.key_considerations &&
    selectedMatterData?.key_considerations?.length > 0;
  const hasSummaries =
    selectedMatterData?.summaries && selectedMatterData?.summaries?.length > 0;
  const mainContentRef = useRef<HTMLDivElement>(null);

  // Scroll to top when selectedMatterId changes
  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedMatterData?.id]);

  return (
    <Stack direction="row" sx={{ height: '100vh' }}>
      <Box sx={{ flexShrink: 1, maxWidth: '280px' }}>
        <Sidebar />
      </Box>

      {hasExistingMatters ? (
        <Stack
          ref={mainContentRef}
          key={selectedMatterData?.id}
          direction="column"
          spacing={6}
          sx={{
            p: 6,
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <MatterTitle />
          {hasSummaries && <Summaries />}
          {hasKeyConsiderations && <KeyConsiderations />}
          <IntakeCallSection />
          {/* <Matter Client Form /> */}
          {selectedMatterData?.clients && (
            <MatterClientView client={selectedMatterData.clients[0]} />
          )}
          {/* <Matter Details Form /> */}
          {selectedMatterData?.id && (
            <MatterDetailsContent matter={selectedMatterData} />
          )}
        </Stack>
      ) : (
        <WelcomeScreen />
      )}
    </Stack>
  );
};

export default Cms;
