import {
  LocalizationProvider,
  TimePicker,
  TimePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { forwardRef } from 'react';

export const TimeFormField = forwardRef<HTMLDivElement, TimePickerProps<Dayjs>>(
  ({ ...rest }, ref) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker ref={ref} sx={{ width: '100%' }} {...rest} />
      </LocalizationProvider>
    );
  },
);

TimeFormField.displayName = 'TimeFormField';
