import {
  MatterDetailsBase,
  MatterDetailsUpdate,
} from '../services/types/matter-details.types';

export const prepareMatterDetailsForUpdate = (
  matterDetails: MatterDetailsBase,
): MatterDetailsUpdate => {
  const { id, created_at, modified_at, ...sanitizedDetails } = matterDetails;
  return sanitizedDetails;
};
