import { Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import useMatterSelection from '../hooks/useMatterSelection';
import { usePatchMatterMutation } from '../services/api';
import { DocumentTypes } from '../services/api/documentService';
import EditableTypography from './base/EditableTypography';
import { DocumentButton } from './documents/DocumentButton';
import { RecordsRequestButton } from './records-request/RecordsRequestButton';

const MatterTitle: React.FC = () => {
  const { selectedMatterData: matterData } = useMatterSelection();

  const [patchMatterMutation] = usePatchMatterMutation();

  const [name, setName] = useState(matterData?.name || '');

  useEffect(() => {
    setName(matterData?.name || '');
  }, [matterData?.name]);

  const handleNameChange = async (matterId: string, updatedValue: string) => {
    if (updatedValue === matterData?.name) {
      return;
    }
    try {
      await patchMatterMutation({
        matter_id: matterId,
        patch: { name: updatedValue },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div>
      <Stack
        direction="row"
        gap="24px"
        marginBottom={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={0}>
          {matterData?.id && (
            <EditableTypography
              variant="h1"
              style={{}}
              value={name}
              onBlur={() => handleNameChange(matterData?.id, name)}
              onChange={(value) => setName(value as string)}
            />
          )}
          <Typography variant="h4" color="info.light">
            {matterData?.type}
          </Typography>
        </Stack>
        <Stack direction="row" gap="24px">
          {matterData?.id && (
            <Stack direction="row" gap="24px">
              <DocumentButton
                matterId={matterData?.id}
                documentType={DocumentTypes.ENGAGEMENT_LETTER}
                mode="Contract"
              />
              <DocumentButton
                matterId={matterData?.id}
                documentType={
                  DocumentTypes.FIRST_PARTY_LETTER_OF_REPRESENTATION
                }
                mode="DocGen"
              />
              <DocumentButton
                matterId={matterData?.id}
                documentType={
                  DocumentTypes.THIRD_PARTY_LETTER_OF_REPRESENTATION
                }
                mode="DocGen"
              />
              <RecordsRequestButton matterId={matterData?.id} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default MatterTitle;
