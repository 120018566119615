import { Divider, Stack, Typography } from '@mui/material';
import { DocumentTypes } from '../../services/api/documentService';
import { documentConfig } from '../../utils/document-config';
import { DocumentPreview } from './DocumentPreview';

export const DownloadDocument = ({
  matterId,
  documentId,
  documentType,
}: {
  matterId: string;
  documentId: string;
  documentType: DocumentTypes;
}) => {
  const config = documentConfig[documentType];

  return (
    <Stack
      maxHeight="90vh"
      alignItems="center"
      direction="column"
      gap={4}
      padding={4}
    >
      <Typography variant="h2">{config.previewTitle}</Typography>
      <Divider flexItem />
      <DocumentPreview
        documentId={documentId}
        matterId={matterId}
        documentType={documentType}
      />
    </Stack>
  );
};
